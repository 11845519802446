import RouteMeta from '@/types/RouteMeta'

const generalRoutes = [
  {
    path: '/',
    redirect: () => ({
      name:
        import.meta.env.VITE_MODE === 'local' ||
        window.location.href.includes(
          import.meta.env.VITE_B2C_BASE_URL_FALLBACK as string,
        )
          ? 'ds'
          : 'b2b',
    }),
  },
  {
    path: '/datenschutz',
    name: 'Dataprotection',
    component: () => import('@/units/ds/views/Dataprotection.vue'),
    meta: {
      allowUnauthorized: true,
      navItem: false,
      title: 'Dataprotection',
      suppressCmp: true,
    } as RouteMeta,
  },
  {
    path: '/dataprotection',
    redirect: () => ({
      name: 'Dataprotection',
    }),
  },
  {
    path: '/impressum',
    name: 'Imprint',
    component: () => import('@/pages/ImprintView.vue'),
    meta: {
      allowUnauthorized: true,
      navItem: false,
      title: 'Imprint',
      suppressCmp: true,
    } as RouteMeta,
  },
  {
    path: '/imprint',
    redirect: () => ({
      name: 'Imprint',
    }),
  },
  {
    path: '/documents/:uuid/preview',
    name: 'DocumentsPreview',
    component: () => import('@/pages/DocumentPreviewView.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'PageNotFound',
    component: () =>
      import(
        /* webpackChunkName: "PageNotFound" */ '@/pages/PageNotFoundView.vue'
      ),
    meta: {
      navItem: false,
      title: '404 - Seite nicht gefunden',
    } as RouteMeta,
  },
  {
    path: '/lebenslauf-generator',
    name: 'publiccvgenerator',
    component: () =>
      import('@/units/ds/views/publiccvgenerator/PublicCvGeneratorView.vue'),
    redirect: () => ({
      name: 'publiccvgenerator.start',
    }),
    meta: {
      allowUnauthorized: true,
      navItem: false,
      color: 'orange',
    } as RouteMeta,
    children: [
      {
        path: 'start',
        name: 'publiccvgenerator.start',
        component: () =>
          import(
            '@/units/ds/views/publiccvgenerator/PublicCvGeneratorStartView.vue'
          ),
        meta: {
          allowUnauthorized: true,
          navItem: false,
        } as RouteMeta,
      },
      {
        path: 'input',
        name: 'publiccvgenerator.input',
        component: () =>
          import(
            '@/units/ds/views/publiccvgenerator/PublicCvGeneratorInputView.vue'
          ),
        redirect: () => ({
          name: 'publiccvgenerator.input.profil',
        }),
        meta: {
          allowUnauthorized: true,
          navItem: false,
        } as RouteMeta,
        children: [
          {
            path: 'profil',
            name: 'publiccvgenerator.input.profil',
            component: () =>
              import(
                '@/units/ds/views/publiccvgenerator/PublicCvGeneratorInputProfilView.vue'
              ),
          },
          {
            path: 'education',
            name: 'publiccvgenerator.input.education',
            component: () =>
              import(
                '@/units/ds/views/publiccvgenerator/PublicCvGeneratorInputEducationView.vue'
              ),
          },
          {
            path: 'experience',
            name: 'publiccvgenerator.input.experience',
            component: () =>
              import(
                '@/units/ds/views/publiccvgenerator/PublicCvGeneratorInputExperienceView.vue'
              ),
          },
          {
            path: 'knowledge',
            name: 'publiccvgenerator.input.knowledge',
            component: () =>
              import(
                '@/units/ds/views/publiccvgenerator/PublicCvGeneratorInputKnowledgeView.vue'
              ),
          },
          {
            path: 'hobbies',
            name: 'publiccvgenerator.input.hobbies',
            component: () =>
              import(
                '@/units/ds/views/publiccvgenerator/PublicCvGeneratorInputHobbiesView.vue'
              ),
          },
        ],
      },
      {
        path: 'send',
        name: 'publiccvgenerator.send',
        component: () =>
          import(
            '@/units/ds/views/publiccvgenerator/PublicCvGeneratorSendView.vue'
          ),
        meta: {
          allowUnauthorized: true,
          navItem: false,
        } as RouteMeta,
      },
      {
        path: 'final',
        name: 'publiccvgenerator.final',
        component: () =>
          import(
            '@/units/ds/views/publiccvgenerator/PublicCvGeneratorFinalView.vue'
          ),
        meta: {
          allowUnauthorized: true,
          navItem: false,
        } as RouteMeta,
      },
    ],
  },
  {
    path: '/newsletter-unsubscription',
    name: 'newsletter-unsubscription',
    component: () =>
      import('@/units/ds/views/profile/NewsletterUnsubscription.vue'),
    meta: {
      allowUnauthorized: true,
      title: 'Newsletter Unsubscription',
      navItem: false,
      topNav: false,
      suppressCmp: true,
    } as RouteMeta,
  },
]

export default generalRoutes
