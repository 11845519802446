<template>
  <div
    class="w-full h-screen flex flex-col items-center justify-center max-w-sm mx-auto text-center"
  >
    <main>
      <img
        v-if="!error"
        src="https://res.cloudinary.com/iubh/image/upload/v1613575530/07%20-%20Pages/Unit%20Bilder/Duales%20Studium/Bewerberportal/loading-bewerberportal-paper-p-unscreen_oa0wvh.gif"
        alt=""
      />
      <div class="max-w-480">
        <div v-if="error">
          <head-line level="h2" bg="blue">{{ $t('general.error') }}</head-line>
          <div class="mt-6">
            <div class="text-error font-bold">
              {{ $t('general.error') }}: {{ error }}
            </div>
            <div class="mt-4">
              Lade die Seite neu oder
              <span
                class="underline font-bold cursor-pointer"
                @click="resetPage"
                >setze sie hier zurück</span
              >
            </div>
          </div>
        </div>
        <div v-else>
          <head-line
            level="h2"
            bg="blue"
            @click="lgtCnt > 5 ? logoutUser() : lgtCnt++"
            >{{ $t('general.loading.wait') }}</head-line
          >
          <p class="mt-4">
            {{
              appStore.state.isB2b
                ? $t('general.loading.your-data-formal')
                : $t('general.loading.your-data')
            }}
          </p>
        </div>
      </div>
    </main>
    <div class="fixed bottom-4 text-sm">
      <router-link
        to="/impressum"
        target="_blank"
        class="cursor-pointer underline"
        @click="
          appService.pushToDataLayer('footer', 'Impressum', null, 'footer')
        "
        >Impressum</router-link
      >
      |
      <router-link
        to="/datenschutz"
        target="_blank"
        class="cursor-pointer underline"
        @click="
          appService.pushToDataLayer('footer', 'Datenschutz', null, 'footer')
        "
        >Datenschutz</router-link
      >
      |
      <span
        onclick="window.UC_UI.showSecondLayer()"
        class="cursor-pointer underline"
        @click="appService.pushToDataLayer('footer', 'Cookies', null, 'footer')"
        >Cookies</span
      >
    </div>
  </div>
</template>

<script setup lang="ts">import { ref as _ref } from 'vue';

import HeadLine from '@/components/HeadLine.vue'
import appStore, { loadInitialData } from '@/stores/app.store'
import authStore from '@/stores/auth.store'
import { computed, onMounted, watch } from 'vue'
import { trackEvent } from '@/services/gtm.service'
import appService from '@/services/app.service'
import { useRoute } from 'vue-router'

const route = useRoute()

const token = computed(() => authStore.state.token)

let error = _ref(null)

let lgtCnt = _ref<number>(1)

const resetPage = () => {
  trackEvent('ResetPage', 'logout')
  localStorage.clear()
  window.location.replace('/')
}

onMounted(async () => {
  setTimeout(() => {
    if (
      route.meta?.allowUnauthorized ||
      window?.location?.href?.includes('lebenslauf-generator')
    ) {
      return
    }

    if (token.value) {
      try {
        loadInitialData()
      } catch (err: any) {
        error.value = err
      }
    } else {
      const tokenWatch = watch(token, async (token, prevValue) => {
        if (token) {
          tokenWatch()
          try {
            loadInitialData()
          } catch (err: any) {
            error.value = err
          }
        }
      })
    }
  }, 300)
})

const logoutUser = () => {
  if (import.meta.env.VITE_MODE !== 'production') {
    authStore.logoutUser()
  }
}
</script>
