export const languageOptions = [
  {
    language: 'Deutsch',
    code: 'de',
  },
  {
    language: 'Englisch',
    code: 'en',
  },
  {
    language: 'Spanisch',
    code: 'es',
  },
  {
    language: 'Französisch',
    code: 'fr',
  },
  {
    language: 'Italienisch',
    code: 'it',
  },
  {
    language: 'Russisch',
    code: 'ru',
  },
  {
    language: 'Polnisch',
    code: 'pl',
  },
  {
    language: 'Arabisch',
    code: 'ar',
  },
  {
    language: 'Persisch',
    code: 'fa',
  },
  {
    language: 'Türkisch',
    code: 'tr',
  },
]
