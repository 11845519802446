import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import authStore from '@/stores/auth.store'
import { EnumsDomStorage } from '@/types/enums'
import appStore from '@/stores/app.store'
import ApiError from '@/types/ApiError'
import JobOpeningType from '@/units/b2b/types/JobOpeningType'
import PublicCvGeneratorSendData from '@/units/ds/types/PublicCvGeneratorSendData'
import Metadata from '@/types/Metadata'
import { datadogLogs } from '@datadog/browser-logs'
import accountStore from '@/stores/account.store'

const apiClient: AxiosInstance = axios.create({
  baseURL:
    import.meta.env.VITE_API_BASE_URL +
    (window.location.href.includes(
      import.meta.env.VITE_B2B_BASE_URL as string,
    ) ||
    window.location.href.includes(
      import.meta.env.VITE_B2B_BASE_URL_FALLBACK as string,
    )
      ? '/b2b/v1'
      : '/v1'),
  withCredentials: true,
})

const applicationService: AxiosInstance = axios.create({
  baseURL: import.meta.env.VITE_APPLICATION_SERVICE_BASE_URL,
  withCredentials: false,
})

/** Add headers and authorization for application service **/
// @ts-ignore
applicationService.interceptors.request.use((config: AxiosRequestConfig) => {
  config.headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Accept-Language': 'de', // language to "de", otherwise it will depending on users browser
  }
  return config
})

/** Add headers and authorization for our backend **/
// @ts-ignore
apiClient.interceptors.request.use((config: AxiosRequestConfig) => {
  let contentType = 'application/json'

  if (config.url === '/documents/upload') {
    contentType = 'multipart/form-data'
  }

  config.headers = {
    Accept: 'application/json',
    'Content-Type': contentType,
    Authorization: authStore.state.token
      ? 'Bearer ' + authStore.state.token
      : '',
  }

  return config
})

/** fetch errors and auth-problems (redirect to login) **/
apiClient.interceptors.response.use(
  (response: AxiosResponse) => {
    if (response.data.cookie) {
      document.cookie =
        '_ga=' +
        response.data.cookie.code +
        '; path=/; expires=' +
        response.data.cookie.expiresAt +
        ';'
    }

    return response
  },
  async (error: any) => {
    const errorObject = JSON.parse(JSON.stringify(error))

    const customErrors = error.response?.data?.errors

    const errorStatusCode = errorObject.status

    datadogLogs.logger.error(
      '[ApiService] API Response Error',
      { uid: accountStore.state.account.uuid },
      error,
    )

    if ([401, 403, 404].includes(errorStatusCode)) {
      const backendErrorCode = error.response?.data?.error?.code
      console.log('backendErrorCode!', backendErrorCode)
      if (backendErrorCode) {
        // Unauth-Request -> Redirect to auth login page
        if ([1000, 1003, 1004].includes(backendErrorCode)) {
          // UNAUTHENTICATED -> Go to Login, but save current path
          const path =
            window.location.pathname +
            window.location.search +
            window.location.hash
          sessionStorage.setItem(EnumsDomStorage.ORIGIN_URL_PATH, path)
          localStorage.removeItem(EnumsDomStorage.AUTH_TOKEN)
          await appStore.redirectToLogin()
        }
        if (backendErrorCode === 1002) {
          localStorage.clear()
          await appStore.redirectToLogin()
        }
        appStore.setApiError(
          backendErrorCode ?? errorObject.status,
          errorObject.response?.data?.error?.message ?? errorObject.message,
        )
      }
      return Promise.reject({
        message: errorObject.message ?? 'Undefined request error',
        code: backendErrorCode ?? errorObject.status,
        customErrors: customErrors,
      } as ApiError)
    }
    return Promise.reject({
      message: errorObject.message ?? 'Undefined request error',
      code: errorObject.status,
      customErrors: customErrors,
    } as ApiError)
  },
)

/** Return list of available endpoints **/
const auth = {
  post: (accessToken: string, idToken?: string | null): Promise<any> =>
    apiClient.post('/auth', { accessToken: accessToken, idToken: idToken }),
}

const account = {
  get: (): Promise<any> => apiClient.get('/account'),
  getReferralLink: (): Promise<any> => apiClient.get('/account/referral-link'),
  patch: (params: object): Promise<any> => apiClient.patch('/account', params),
  postFeedback: (params: object) =>
    apiClient.post('/feedback', {
      ...params,
      agent: navigator?.userAgent,
      screenWidth: window?.innerWidth,
      screenHeight: window?.innerHeight,
    }),
  sendMailVerification: () => apiClient.post('/account/email-verification'),
  putMetadata: (metadata: Metadata): Promise<any> =>
    apiClient.put('/metadata', { ...metadata }),
  setOppyLost: (params: object) => apiClient.patch('/account/lost', params),
}

const unit = {
  getUnit: (): Promise<any> => apiClient.get('/unit'),
}

const documents = {
  get: (): Promise<any> => apiClient.get('/documents'),
  getB2b: (companyId: number | null): Promise<any> =>
    apiClient.get(`/documents/${companyId}`),
  delete: (uuid: string): Promise<any> =>
    apiClient.delete('/documents/' + uuid),
  patch: (uuid: string, category: string, type?: string): Promise<any> =>
    apiClient.patch('/documents/' + uuid, { category: category, type: type }),
  post: (fileObj: FormData, onUploadProgress: (progress: number) => void) => {
    return apiClient.post('/documents/upload', fileObj, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        Authorization: authStore.state.token
          ? 'Bearer ' + authStore.state.token
          : '',
      },
      onUploadProgress: (progressEvent: any) => {
        const progress = Math.trunc(
          Math.round((progressEvent.loaded * 100) / progressEvent.total),
        )
        onUploadProgress(progress)
      },
    })
  },
  post64: (fileObj: object, onUploadProgress: (progress: number) => void) => {
    return apiClient.post('/documents/upload?base64=true', fileObj, {
      onUploadProgress: (progressEvent: any) => {
        const progress = Math.trunc(
          Math.round((progressEvent.loaded * 100) / progressEvent.total),
        )
        onUploadProgress(progress)
      },
    })
  },
  preview: (uuid: string): Promise<any> =>
    apiClient.get('/documents/' + uuid + '/preview', { responseType: 'blob' }),
}

const resources = {
  getLanguages: (): Promise<any> => apiClient.get('/languages'),
  getB2bNews: (page: number = 1, perPage: number = 3): Promise<any> => {
    const queryString = `?page=${page}&perPage=${perPage}`

    return apiClient.get(`/news${queryString}`)
  },
  getB2bNewsById: (id: number): Promise<any> => apiClient.get(`/news/${id}`),
  getPdfFromHtml: (cat: string, html: string): Promise<any> =>
    apiClient.post(
      '/print-templates/upload',
      { type: cat, html: html },
      {
        responseType: 'blob',
      },
    ),
  getJobOpenings: (): Promise<any> => apiClient.get('/job-openings'),
  getJobOpeningFilterOptions: (): Promise<any> =>
    apiClient.get('/job-openings/filter-options'),
  /**
   * Will be replaced by the new endpoint getFilteredPositions
   *
   * @deprecated
   */
  getRelatedJobOpenings: (page?: number): Promise<any> =>
    apiClient.get(`/job-openings/related?page=${page ?? 1}`),
  getFilteredJobOpenings: (
    page?: number,
    courses?: string[],
    campuses?: string[],
    intakes?: string[],
  ): Promise<any> => {
    let path = '/job-openings/filtered'
    path += `?page=${page ?? 1}`

    if (courses?.length) path += `&courses=${courses.join(',')}`
    if (campuses?.length) path += `&campuses=${campuses.join(',')}`
    if (intakes?.length) path += `&intakes=${intakes.join(',')}`

    return apiClient.get(path)
  },
  getInteractedJobOpenings: (): Promise<any> =>
    apiClient.get(`/job-openings/interacted`),
  getJobOpeningDetails: (uuid: string): Promise<any> =>
    apiClient.get(`/job-openings/${uuid}`),
  setJobOpeningInterest: (uuid: string): Promise<any> =>
    apiClient.post(`/job-openings/${uuid}/express-interest`),
  setJobOpeningDisInterest: (uuid: string): Promise<any> =>
    apiClient.post(`/job-openings/${uuid}/express-disinterest`),
  getCampaigns: (): Promise<any> => apiClient.get(`/campaigns`),
  registerForCampaigns: (uuid: string): Promise<any> =>
    apiClient.post(`/campaigns/${uuid}/register`),
  participateCampaign: (uuid: string): Promise<any> =>
    apiClient.post(`/campaigns/${uuid}/participate`),
  getNotificationSettings: (): Promise<any> =>
    apiClient.get('/notification-settings'),
  updateNotificationSettings: (params: object): Promise<any> =>
    apiClient.put(`/notification-settings`, params),
}

// General endpoints (eg CV-Generator)
const general = {
  sendCvData: (cvData: PublicCvGeneratorSendData): Promise<any> =>
    apiClient.post('/cv-generator/send/upload', cvData),
  cvGeneratorPdf: (cat: string, html: string): Promise<any> =>
    apiClient.post(
      '/cv-generator/upload',
      { type: cat, html: html },
      {
        responseType: 'blob',
      },
    ),
  unsubscribeNewsletter: (tid: string): Promise<any> =>
    apiClient.post(`/unsubscribe/${tid}`),
  sendTrackingId: (tid: String): Promise<any> =>
    apiClient.post(`/t/${tid}`, {}),
}

// B2B Endpoints
const b2b = {
  getUser: (): Promise<any> => apiClient.get('/me'),
  putConsents: (
    consents: Array<{ type: string; hasOptedIn: boolean }>,
  ): Promise<any> => apiClient.put('/consents', consents),
  patchUser: (params: object): Promise<any> => apiClient.patch(`/me`, params),
  setActiveCompany: (companyId: number): Promise<any> =>
    apiClient.post(`/me/companies/${companyId}/set-default`),
  putMetadata: (metadata: Metadata): Promise<any> =>
    apiClient.put('/metadata', { ...metadata }),

  getCompany: (companyId: string | number): Promise<any> =>
    apiClient.get(`/companies/${companyId}`),
  patchCompanyMasterData: (
    companyId: string | number,
    params: object,
  ): Promise<any> => apiClient.patch(`/companies/${companyId}`, params),
  patchCompanyPortrait: (
    companyId: string | number,
    params: object,
  ): Promise<any> =>
    apiClient.patch(`/companies/${companyId}/portrait`, params),
  patchCompanyPaymentDetails: (
    companyId: string | number,
    params: object,
  ): Promise<any> =>
    apiClient.patch(`/companies/${companyId}/payment-details`, params),
  patchCompanyPaymentType: (
    companyId: string | number,
    params: object,
  ): Promise<any> =>
    apiClient.patch(`/companies/${companyId}/payment-type`, params),
  acceptCompanyInvitation: (companyId: string | number): Promise<any> =>
    apiClient.post(`/me/companies/${companyId}/accept-invitation`),
  declineCompanyInvitation: (companyId: string | number): Promise<any> =>
    apiClient.post(`/me/companies/${companyId}/decline-invitation`),

  getBankDetailsByIban: (iban: string): Promise<any> =>
    applicationService.get(`/bank-info/${iban}`),
  getInvoices: (): Promise<any> => apiClient.get('/accounting/invoices'),
  getNotices: (): Promise<any> => apiClient.get('/accounting/notices'),
  getNotifications: (limit?: number, offset?: number): Promise<any> =>
    apiClient.get('/notifications', {
      params: { limit: limit ?? 4, offset: offset ?? 0 },
    }),

  setNotificationRead: (id: number): Promise<any> =>
    apiClient.patch(`/notifications/${id}/read`),
  setNotificationReadAll: (): Promise<any> =>
    apiClient.patch(`/notifications/read`),
  sendNotification: (params: object): Promise<any> =>
    apiClient.post(`/notifications`, params),

  getNotificationSettings: (): Promise<any> =>
    apiClient.get('/notification-settings'),
  updateNotificationSettings: (params: object): Promise<any> =>
    apiClient.put(`/notification-settings`, params),

  // Bedarfsmeldungen / JobOpenings
  // get list of bedarfsmeldungen/jobopenings in short form
  getCompanyJobOpeningsOverview: (companyId: number): Promise<any> =>
    apiClient.get(`/companies/${companyId}/job-openings/overview`),
  createCompanyJobOpeningsOverview: (
    companyId: number,
    postData: JobOpeningType,
  ): Promise<any> =>
    apiClient.post(`/companies/${companyId}/job-openings`, postData),
  patchCompanyJobOpeningsOverview: (
    companyId: number,
    jobOpeningId: number,
    patchData: JobOpeningType,
  ): Promise<any> =>
    apiClient.patch(
      `/companies/${companyId}/job-openings/${jobOpeningId}`,
      patchData,
    ),
  getCompanyJobOpeningDetails: (
    companyId: number,
    jobOpeningId: string,
  ): Promise<any> =>
    apiClient.get(`/companies/${companyId}/job-openings/${jobOpeningId}`),
  deleteCompanyJobOpeningDetails: (
    companyId: number,
    jobOpeningId: string,
  ): Promise<any> =>
    apiClient.delete(`/companies/${companyId}/job-openings/${jobOpeningId}`),

  arrangeInterviewWithJobApplicant: (
    jobApplicantUuid: string,
    interviewDateSuggestions: string,
  ): Promise<any> =>
    apiClient.post(`/applicants/${jobApplicantUuid}/arrange-interview`, {
      interviewDateSuggestions: interviewDateSuggestions,
    }),
  rejectJobApplicant: (
    jobApplicantUuid: string,
    feedbackText: string,
  ): Promise<any> =>
    apiClient.post(`/applicants/${jobApplicantUuid}/reject`, { feedbackText }),
  acceptJobApplicant: (
    jobApplicantUuid: string,
    mismatchModel: object,
  ): Promise<any> =>
    apiClient.post(`/applicants/${jobApplicantUuid}/accept`, mismatchModel),

  // TODO: define searchData type
  searchCompanyJobOpeningApplicants: (
    companyId: number,
    jobOpeningId: string,
    searchData: object,
  ): Promise<any> =>
    apiClient.post(
      `/companies/${companyId}/job-openings/${jobOpeningId}/applicants`,
      searchData,
    ),

  getApplicant: (
    companyId: number | string,
    jobOpeningId: number | string,
    applicantId: number | string,
  ): Promise<any> =>
    apiClient.get(
      `/companies/${companyId}/job-openings/${jobOpeningId}/applicants/${applicantId}`,
    ),
  getApplicantContract: (
    companyId: number,
    jobOpeningId: number | string,
    applicantId: number | string,
  ): Promise<any> =>
    apiClient.get(
      `/companies/${companyId}/job-openings/${jobOpeningId}/applicants/${applicantId}/contract`,
    ),
  upsertApplicantContract: (
    companyId: number,
    jobOpeningId: number | string,
    applicantId: number | string,
    data: object,
  ): Promise<any> =>
    apiClient.post(
      `/companies/${companyId}/job-openings/${jobOpeningId}/applicants/${applicantId}/contract`,
      data,
    ),

  getApplicantComments: (
    companyId: number | string,
    jobOpeningId: number | string,
    applicantId: number | string,
  ): Promise<any> =>
    apiClient.get(
      `/companies/${companyId}/job-openings/${jobOpeningId}/applicants/${applicantId}/comments`,
    ),
  createApplicantComment: (
    companyId: number | string,
    jobOpeningId: number | string,
    applicantId: number | string,
    data: object,
  ): Promise<any> =>
    apiClient.post(
      `/companies/${companyId}/job-openings/${jobOpeningId}/applicants/${applicantId}/comment`,
      data,
    ),
  deleteApplicantComment: (
    companyId: number | string,
    jobOpeningId: number | string,
    applicantId: number | string,
    commentId: number | string,
  ): Promise<any> =>
    apiClient.delete(
      `/companies/${companyId}/job-openings/${jobOpeningId}/applicants/${applicantId}/comment/${commentId}`,
    ),
  patchApplicantComment: (
    companyId: number | string,
    jobOpeningId: number | string,
    applicantId: number | string,
    data: object,
  ): Promise<any> =>
    apiClient.patch(
      `/companies/${companyId}/job-openings/${jobOpeningId}/applicants/${applicantId}/comment`,
      data,
    ),

  getCompanyEmployees: (companyId: number): Promise<any> =>
    apiClient.get(`/companies/${companyId}/employees`),
  getCompanyEmployee: (companyId: number, employeeId: number): Promise<any> =>
    apiClient.get(`/companies/${companyId}/employees/${employeeId}`),
  createCompanyEmployee: (companyId: number, params: object): Promise<any> =>
    apiClient.post(`/companies/${companyId}/employees`, params),
  editCompanyEmployee: (
    companyId: number,
    employeeId: number,
    params: object,
  ): Promise<any> =>
    apiClient.patch(`/companies/${companyId}/employees/${employeeId}`, params),
  resendCompanyEmployeeInvite: (
    companyId: number,
    employeeId: number,
  ): Promise<any> =>
    apiClient.post(
      `/companies/${companyId}/employees/${employeeId}/resend-invitation`,
    ),
  deleteCompanyEmployee: (
    companyId: number,
    employeeId: number,
  ): Promise<any> =>
    apiClient.delete(`/companies/${companyId}/employees/${employeeId}`),

  postFeedback: (params: object): Promise<any> =>
    apiClient.post('/feedback', {
      ...params,
      agent: navigator?.userAgent,
      screenWidth: window?.innerWidth,
      screenHeight: window?.innerHeight,
    }),
}

export default {
  account,
  unit,
  auth,
  documents,
  resources,
  apiClient,
  b2b,
  applicationService,
  general,
}
