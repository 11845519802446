import { datadogLogs } from '@datadog/browser-logs'

export const activateDatadog = () => {
  const modeToEnvMap: { [key: string]: string } = {
    local: 'local',
    development: 'dev',
    staging: 'non-prod',
    production: 'prod',
  }

  const token: string = import.meta.env.VITE_DD_TOKEN as string
  const mode: string = import.meta.env.VITE_MODE as string
  const env: string | null = modeToEnvMap[mode] ?? null

  if (env === null) {
    console.error('Datadog environment not found.')
    return
  }

  datadogLogs.init({
    clientToken: token,
    site: 'datadoghq.eu',
    service: 'applicantportal',
    env: env,
    version: `${import.meta.env.VITE_APP_VERSION}`,
    forwardConsoleLogs: ['debug', 'warn'],
    forwardErrorsToLogs: true,
  })
}

export default activateDatadog
