<template>
  <component :is="level" :class="[headlineSize, 'break-words', props.hClass]">
    <span
      :class="`bg-${props.bg} ${props.bg !== 'black' ? 'text-black' : 'text-white'} ${props.noPadding ? '' : 'px-2'}`"
      class="uppercase font-bold leading-normal box-decoration-clone"
    >
      <slot />
    </span>
  </component>
</template>

<script setup lang="ts">
import { computed } from 'vue'

const props = defineProps<{
  level: string
  bg?: string
  noPadding?: boolean
  hClass?: string
}>()

const headlineSize = computed(() => {
  switch (props.level) {
    case 'h1':
      return 'text-3xl lg:text-5xl'
    case 'h2':
      return 'text-2xl lg:text-4xl'
    case 'h3':
      return 'text-xl lg:text-3xl'
    case 'h4':
      return 'text-lg lg:text-2xl'
    case 'h5':
      return 'text-base'
    default:
      return 'text-base'
  }
})
</script>
