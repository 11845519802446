export enum EnumsDomStorage {
  ACTIVE_UNIT_ID = 'active-unit-id',
  ACTIVE_UNIT = 'active-unit',
  ACTIVE_APPLICATION = 'active-application',
  LANGUAGE = 'language',
  AUTH_TOKEN = 'auth-token',
  IGNORE_SM = 'ignore-sm',

  ORIGIN_URL_PATH = 'origin_url_path',
  ORIGIN_URL_QUERIES = 'origin_url_queries',

  B2B_ACTIVE_COMPANY_ID = 'active_company',
}

export enum DocumentType {
  Picture = 'Picture',
  EmployeeProfilePicture = 'EmployeeProfilePicture',
  Cv = 'CV',
  Contract = 'Contract',
  Certificate = 'Certificate',
  CertifiedCertificate = 'CertifiedCopyUEC',
  Other = 'Other',
  ApplicationForRecognition = 'Application for recognition',
  IdentificationDocument = 'Identification document',
  CertificateGermanSkills = 'Certificate German skills',
  DeRegistrationCertificate = 'De-registration certificate',
  CertificateHealthInsurance = 'Certificate health insurance',
  TranscriptOfRecords = 'TranscriptOfRecords',
  EnglishKnowledgeCertificate = 'EnglishKnowledgeCertificate',
  ProofOfWorkExperience = 'ProofOfWorkExperience',
}

export enum FrontendErrorCode {
  AUTH_ERROR = 10001,
  NO_UNIT = 10002,
  NO_APPLICATION = 10002,
  FILE_BASE64 = 10003,
  FETCH_ERROR = 10004,
}

export enum EnumsB2bPermissions {
  B2B_PERMISSION_EMPLOYEES_ADD = 'create employees',
  B2B_PERMISSION_EMPLOYEES_EDIT = 'edit employees',
  B2B_PERMISSION_EMPLOYEES_DELETE = 'delete employees',

  B2B_PERMISSION_COMPANY_DATA = 'edit company master data',
  B2B_PERMISSION_COMPANY_PORTRAIT = 'edit company portrait',
  B2B_PERMISSION_COMPANY_PAYMENT = 'edit company payment details',

  B2B_PERMISSION_JOBOPENING_CREATE = 'create job openings',
  B2B_PERMISSION_JOBOPENING_EDIT = 'edit job openings',

  B2B_PERMISSION_CONTRACTS_REQUEST = 'request contracts',
  B2B_PERMISSION_CONTRACTS_EDIT = 'edit contracts',
  B2B_PERMISSION_CONTRACTS_DOWNLOAD = 'view student contracts',
}

export enum DirectionType {
  LEFT,
  RIGHT,
}

export enum EnumsJobOpeningStatusText {
  APPLICANT_IS_INTERESTED = 'applicant is interested',
  COMPANY_IS_INTERESTED = 'company is interested',
  COMPANY_HAS_ACCEPTED = 'company has accepted',
  POSITION_EXPIRED = 'job opening expired',
}

export enum MetadataKey {
  // Common
  FEEDBACK_GIVEN = 'feedbackGiven',

  // B2B
  HIDE_DMS_INFO_MODAL = 'hideDmsInfoModal',
  HIDE_DMS_INFO_MODAL_HOME = 'hideDmsInfoModalHome',
  HIDE_DMS_INFO_MODAL_JOB_OPENINGS = 'hideDmsInfoModalJobOpenings',
  HIDE_PAYMENT_DETAILS_WARNING = 'hidePaymentDetailsWarning',
  HIDE_SUPPORT_TICKET_NOTICE = 'hideSupportTicketNotice',
  HIDE_COURSE_OF_STUDY_INFO = 'hideCourseOfStudyInfo',

  // B2C
  ONBOARDING_DONE = 'onboardingDone',
  INTERACTED_WITH_MOTIVATION_STEP = 'interactedWithMotivationStep',
  VISITED_STUDY_ADVICE_VIEW = 'visitedStudyAdviceView',
  HIDE_B2C_STUDY_MATCH_INTEREST_MODAL = 'hideB2cStudyMatchInterestModal',
  UNLOCKED_STEP2_ALREADY = 'unlockedStep2already',
  UNREAD_JOB_OPENINGS = 'unreadJobOpenings',
}

export enum ConsentType {
  USER_RESEARCH = 'user_research',
}

export enum ConsentStatus {
  NOT_ANSWERED = 'not_answered',
  OPT_IN_SINGLE = 'opt_in_single',
  OPT_IN_DOUBLE = 'opt_in_double',
  OPT_OUT = 'opt_out',
}
