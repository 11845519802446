<template>
  <iu-modal
    v-model="showModal"
    @closed="closeFeedback"
    dynamic-content
    hide-footer
    content-class="lg:w-1/2 xl:w-1/2 2xl:w-1/3 relative"
    :color="color"
  >
    <template v-slot:title>{{ title }}</template>

    <div v-if="submitted" class="flex flex-col">
      <div
        class="bg-gray-50 px-2 lg:px-4 py-2 lg:py-3 flex flex-col items-center"
      >
        <h4 class="text-xl uppercase font-bold text-black py-8">
          {{ thankYouText }}
        </h4>
      </div>
      <div class="bg-gray-100 flex justify-between items-center px-4 py-2">
        <div></div>
        <iu-button type="primary" :no-spacing="true" @click="closeFeedback"
          ><span class="font-bold uppercase px-4 py-2"
            >Schliessen</span
          ></iu-button
        >
      </div>
    </div>

    <div v-else class="w-full items-center">
      <div
        class="flex flex-row h-24 items-center justify-between w-1/2 mx-auto"
      >
        <div
          class="flex flex-col items-center text-xs text-gray-800 cursor-pointer"
          @click="selectedFeedback = 'negative'"
        >
          <img
            v-if="selectedFeedback === 'negative'"
            src="@/assets/images/emoji_negative_col.svg"
            alt="Schlecht"
            class="h-12 mb-2"
          />
          <img
            v-else
            src="@/assets/images/emoji_negative_bw.svg"
            alt="Schlecht"
            class="h-12 mb-2"
          />
          Schlecht
        </div>
        <div
          class="flex flex-col items-center text-xs text-gray-800 cursor-pointer"
          @click="selectedFeedback = 'neutral'"
        >
          <img
            v-if="selectedFeedback === 'neutral'"
            src="@/assets/images/emoji_neutral_col.svg"
            alt="Naja"
            class="h-12 mb-2"
          />
          <img
            v-else
            src="@/assets/images/emoji_neutral_bw.svg"
            alt="Naja"
            class="h-12 mb-2"
          />
          Naja
        </div>
        <div
          class="flex flex-col items-center text-xs text-gray-800 cursor-pointer"
          @click="selectedFeedback = 'positive'"
        >
          <img
            v-if="selectedFeedback === 'positive'"
            src="@/assets/images/emoji_positive_col.svg"
            alt="Super"
            class="h-12 mb-2"
          />
          <img
            v-else
            src="@/assets/images/emoji_positive_bw.svg"
            alt="Super"
            class="h-12 mb-2"
          />
          Super
        </div>
      </div>

      <div>
        <div
          v-if="selectedFeedback === 'negative'"
          class="flex flex-col items-center mt-8"
        >
          <p class="text-xs">Tut uns Leid, das zu hören. Woran lag es?</p>
          <div class="flex flex-wrap w-full xl:w-3/5 justify-center mt-2">
            <label
              v-for="reason in negativeReasons"
              :id="`${reason.id}-label`"
              :key="reason.id"
              :for="`${reason.id}-input`"
              class="text-xs border border-cta px-3 py-1 m-1"
              :class="
                selectedReasons.includes(reason.id)
                  ? 'bg-cta text-white'
                  : 'text-cta'
              "
            >
              <input
                :id="`${reason.id}-input`"
                v-model="selectedReasons"
                class="hidden"
                type="checkbox"
                :value="reason.id"
                :name="`${reason.id}-input`"
                :aria-labelledby="`${reason.id}-label`"
              />
              <span> {{ reason.label }} </span>
            </label>
          </div>
        </div>
        <div
          v-if="selectedFeedback === 'positive'"
          class="flex flex-col items-center mt-8"
        >
          <p class="text-xs">{{ whatDoYouLikeText }}</p>
          <div class="flex flex-wrap w-full xl:w-3/5 justify-center mt-2">
            <label
              v-for="reason in positiveReasons"
              :id="`${reason.id}-label`"
              :key="reason.id"
              :for="`${reason.id}-input`"
              class="text-xs border border-cta px-3 py-1 m-1 cursor-pointer"
              :class="
                selectedReasons.includes(reason.id)
                  ? 'bg-cta text-white'
                  : 'text-cta'
              "
            >
              <input
                :id="`${reason.id}-input`"
                v-model="selectedReasons"
                class="hidden"
                type="checkbox"
                :value="reason.id"
                :name="`${reason.id}-input`"
                :aria-labelledby="`${reason.id}-label`"
              />
              <span> {{ reason.label }} </span>
            </label>
          </div>
        </div>
      </div>

      <div class="mt-6">
        <iu-textarea
          v-model="vuelidateModel.feedbackText"
          name="feedbackText"
          :label="label"
          :max-characters="2000"
        ></iu-textarea>
      </div>

      <div class="bg-gray-100 flex justify-between items-center px-4 py-2">
        <p class="text-xs text-gray-700">{{ sendButtonInfoText }}</p>
        <iu-button
          type="primary"
          :no-spacing="true"
          :disabled="!selectedFeedback"
          @click="sendFeedback"
          ><span class="font-bold uppercase px-4 py-2"
            >Absenden</span
          ></iu-button
        >
      </div>
    </div>
  </iu-modal>
</template>

<script setup lang="ts">import { ref as _ref } from 'vue';

import IuModal from '@/components/modal/IuModal.vue'
import { computed, onMounted, reactive } from 'vue'
import IuTextarea from '@/components/elements/IuTextarea.vue'
import { useVuelidate } from '@vuelidate/core'
import { maxLength } from '@vuelidate/validators'
import IuButton from '@/components/elements/IuButton.vue'

import { trackEvent } from '@/services/gtm.service'
import accountStore from '@/stores/account.store'
import appStore from '@/stores/app.store'
import apiService from '@/services/api.service'
import b2buserStore from '@/units/b2b/stores/b2buser.store'
import { MetadataKey } from '@/types/enums'

const emit = defineEmits(['closed'])

let showModal = _ref(false)

const selectedFeedback = _ref<string | null>(null)

const selectedReasons = _ref(Array<string>())

const state = reactive({
  feedbackText: '',
})

let submitted = _ref(false)

const closeFeedback = () => {
  if (!props.isB2b) {
    storeB2cCloseFeedback()
  } else {
    storeB2bCloseFeedback()
  }

  emit('closed')
}

const storeB2cCloseFeedback = () => {
  trackEvent('UserFeedback', 'closedModal')
  if (!accountStore.getMetadataValue(MetadataKey.FEEDBACK_GIVEN)) {
    // set feedbackGiven, even on close | MPAD-455
    accountStore.updateMetadata(MetadataKey.FEEDBACK_GIVEN, true)
  }
  appStore.setUserFeedback(false)
}

const storeB2bCloseFeedback = () => {
  if (!b2buserStore.getMetadataValue(MetadataKey.FEEDBACK_GIVEN)) {
    b2buserStore.updateMetadata(MetadataKey.FEEDBACK_GIVEN, true)
  }
}

const vuelidateModel = useVuelidate(
  { feedbackText: { maxLength: maxLength(2000) } },
  state,
)

const sendFeedback = () => {
  if (!props.isB2b) {
    sendB2cFeedback()
  } else {
    sendB2bFeedback()
  }
}

const sendB2cFeedback = () => {
  console.log('sendFeedback')
  const reasons = selectedReasons.value.join(', ')
  trackEvent(
    'UserFeedback',
    `${selectedFeedback.value}: ${reasons}${state.feedbackText ? '| ' + state.feedbackText : ''}`,
  )
  if (!accountStore.getMetadataValue(MetadataKey.FEEDBACK_GIVEN)) {
    accountStore.updateMetadata(MetadataKey.FEEDBACK_GIVEN, true)
  }
  apiService.account.postFeedback({
    selected: selectedFeedback.value,
    reasons: reasons,
    feedback: state.feedbackText,
  })
  submitted.value = true
}

const sendB2bFeedback = () => {
  const reasons = selectedReasons.value.join(', ')
  b2buserStore.updateMetadata(MetadataKey.FEEDBACK_GIVEN, true)
  apiService.b2b.postFeedback({
    selected: selectedFeedback.value,
    reasons: reasons,
    feedback: state.feedbackText,
  })
  submitted.value = true
}

const negativeReasons = _ref([
  {
    id: 'confusing',
    label: 'Unübersichtliche Seite',
  },
  {
    id: 'unclear-nav',
    label: 'Unklare nächste Schritte',
  },
  {
    id: 'need-more-help',
    label: 'Zu wenig persönliche Betreuung',
  },
  {
    id: 'data-upload-problems',
    label: 'Probleme beim Daten-Hochladen',
  },
  {
    id: 'other',
    label: 'Sonstiges',
  },
])

const positiveReasons = _ref([
  {
    id: 'clear-navigation',
    label: 'Übersichtliche Seite',
  },
  {
    id: 'clear-next-steps',
    label: 'Klare nächste Schritte',
  },
  {
    id: 'help-ok',
    label: 'Ausreichende Hilfestellungen',
  },
  {
    id: 'usage-ok',
    label: 'Problemloses Eingeben von Daten',
  },
  {
    id: 'other',
    label: 'Sonstiges',
  },
])

const props = defineProps<{
  isB2b?: boolean
}>()

const color = computed(() => (props.isB2b ? 'blue' : null))
const title = computed(() => {
  return props.isB2b
    ? 'Wie gefällt Ihnen das Unternehmensportal bisher?'
    : 'Wie gefällt Dir das Bewerbungsportal bisher?'
})
const label = computed(() => {
  return props.isB2b
    ? 'Sagen Sie uns gerne Ihre Meinung! (optional)'
    : 'Sag uns gerne Deine Meinung! (optional)'
})
const sendButtonInfoText = computed(() => {
  return props.isB2b
    ? 'Ihre Bewertung erfolgt komplett anonym.'
    : 'Deine Bewertung erfolgt komplett anonym.'
})
const whatDoYouLikeText = computed(() => {
  return props.isB2b
    ? 'Das freut uns sehr! Was hat Ihnen gefallen?'
    : 'Das freut uns sehr! Was hat Dir gefallen?'
})
const thankYouText = computed(() => {
  return props.isB2b
    ? 'Vielen Dank für Ihre Bewertung!'
    : 'Vielen Dank für Deine Bewertung!'
})

onMounted(async () => {
  await vuelidateModel.value.$validate()
  showModal.value = true
})
</script>
