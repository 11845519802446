import { useGtm } from '@gtm-support/vue-gtm'
import { RouteLocationNormalized, RouteRecordName } from 'vue-router'

const isB2b =
  window.location.href.includes(import.meta.env.VITE_B2B_BASE_URL as string) ||
  window.location.href.includes(
    import.meta.env.VITE_B2B_BASE_URL_FALLBACK as string,
  )

export const vueGtmOptions = {
  id: isB2b ? 'GTM-MH2GQGZ' : 'GTM-WS3KZL9', // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryPararms: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}]
  /*queryParams: {
      gtm_auth: 'AB7cDEf3GHIjkl-MnOP8qr',
      gtm_preview: 'env-4',
      gtm_cookies_win: 'x',
    },*/
  defer: false, // defaults to false. Script can be set to `defer` to increase page-load-time at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible)
  enabled: false, // will be enabled in App.vue after consents-check
  debug: true, // Whether or not display console logs debugs (optional)
  loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
  trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
}

export const trackEvent = (category: string, label: string) => {
  const $gtm = useGtm()
  // console.log('[GTMService] trackEvent', category, label, `gtm: ${!!($gtm && $gtm.enabled())}`)
  if ($gtm && $gtm.enabled()) {
    $gtm.trackEvent({
      event: 'interaction',
      category,
      action: 'click',
      label,
      value: label,
      noninteraction: false,
    })
  }
}

const ignoredGtmView = [
  'ds.application.documents',
  'ds.application.abilities',
  'ds.application.info',
  'ds.application.interview',
  'ds.application.search',
  'ds.application.contracts',
] // TODO: do it in the units

const _trackView = (name: string, path: string) => {
  const $gtm = useGtm()
  const isIgnored = ignoredGtmView.includes(name)
  // console.log('[GTMService] trackView', name, path, isIgnored)
  if ($gtm && $gtm.enabled() && !isIgnored) {
    $gtm.trackView(name, path)
  }
}

export const trackView = (
  viewName: RouteRecordName | null | undefined,
  viewPath: string,
  from: RouteLocationNormalized,
) => {
  if (!viewName) {
    return
  }
  if (from?.name) {
    _trackView(viewName.toString(), viewPath)
    return
  }
  // from.name is empty -> Reload or first visit
  // so we wait some time for consents_changed
  setTimeout(() => {
    _trackView(viewName.toString(), viewPath)
  }, 1000)
}
