import RouteMeta from '@/types/RouteMeta'

const b2bUnitRoutes = [
  {
    path:
      import.meta.env.VITE_MODE === 'local' ||
      window.location.href.includes(
        import.meta.env.VITE_B2B_BASE_URL_FALLBACK as string,
      )
        ? '/b2b'
        : '/',
    name: 'b2b',
    redirect: () => ({
      name: 'b2b.home',
    }),
    component: () => import('./Index.vue'),
    meta: {
      unitRoute: true,
    },
    children: [
      {
        path: 'home',
        name: 'b2b.home',
        component: () => import('./views/B2bHomeView.vue'),
        meta: {
          title: 'Home',
          icon: 'home',
        } as RouteMeta,
      },
      {
        path: 'impressum',
        name: 'b2b.imprint',
        component: () => import('@/pages/ImprintView.vue'),
        meta: {
          title: 'Impressum',
          allowUnauthorized: true,
          noNav: true,
          suppressCmp: true,
        } as RouteMeta,
      },
      {
        path: 'datenschutz',
        name: 'b2b.privacy',
        component: () => import('./views/B2bDataprotectionView.vue'),
        meta: {
          title: 'Datenschutz',
          allowUnauthorized: true,
          noNav: true,
          suppressCmp: true,
        } as RouteMeta,
      },
      {
        path: 'nutzungsbedingungen',
        name: 'b2b.terms-of-service',
        component: () => import('./views/B2bTermsOfServiceView.vue'),
        meta: {
          title: 'Nutzungsbedingungen',
          allowUnauthorized: true,
          noNav: true,
          suppressCmp: true,
        } as RouteMeta,
      },
      {
        path: 'vertraulichkeit',
        name: 'b2b.confidentiality',
        component: () => import('./views/B2bConfidentialityView.vue'),
        meta: {
          title: 'Vertraulichkeitsvereinbarung',
          allowUnauthorized: true,
          noNav: true,
          suppressCmp: true,
        } as RouteMeta,
      },
      {
        path: 'profile',
        name: 'b2b.profile',
        redirect: () => ({
          name: 'b2b.profile.settings',
        }),
        component: () => import('./views/profile/B2bProfile.vue'),
        meta: {
          title: 'Nutzerkonto',
          icon: 'profile',
        } as RouteMeta,
        children: [
          {
            path: 'settings',
            name: 'b2b.profile.settings',
            component: () => import('./views/profile/B2bProfileSettings.vue'),
          },
          {
            path: 'notifications',
            name: 'b2b.profile.notifications',
            component: () =>
              import('./views/profile/B2bProfileNotifications.vue'),
          },
          {
            path: 'consents',
            name: 'b2b.profile.consents',
            component: () => import('./views/profile/B2bProfileConsents.vue'),
          },
        ],
      },
      {
        path: 'downloads',
        name: 'b2b.downloads',
        component: () => import('./views/B2bDownloadsView.vue'),
        meta: {
          title: 'Downloads',
          icon: 'profile',
        } as RouteMeta,
      },
      {
        path: 'students',
        name: 'b2b.students',
        component: () => import('./views/B2bStudentsView.vue'),
        meta: {
          title: 'Studierende',
          icon: 'graduate',
        } as RouteMeta,
      },
      {
        path: 'upskilling',
        name: 'b2b.upskilling',
        component: () => import('./views/B2bUpskilling.vue'),
        meta: {
          title: 'Personalentwicklung',
          icon: 'graduate',
        } as RouteMeta,
      },
      {
        path: 'job-openings',
        name: 'b2b.job-openings.show',
        component: () => import('./views/JobOpenings/B2bJobOpeningsView.vue'),
        meta: {
          title: 'Bedarfsmeldungen',
          icon: 'graduate', //Todo change icon
        } as RouteMeta,
      },
      {
        path: 'job-openings/:jobOpeningId?/manage',
        name: 'b2b.job-openings.manage',
        redirect: (to: any) => ({
          name: 'b2b.job-openings.manage.study',
          params: { jobOpeningId: to.params.jobOpeningId },
        }),
        component: () =>
          import('./views/JobOpenings/manage/B2bJobOpeningManage.vue'),
        meta: {
          title: 'Anfrage bearbeiten',
          icon: 'graduate',
        } as RouteMeta,
        children: [
          {
            path: 'study',
            name: 'b2b.job-openings.manage.study',
            component: () =>
              import(
                './views/JobOpenings/manage/Step1_B2bJobOpeningManageStudy.vue'
              ),
          },
          {
            path: 'job',
            name: 'b2b.job-openings.manage.job',
            component: () =>
              import(
                './views/JobOpenings/manage/Step2_B2bJobOpeningManageJob.vue'
              ),
          },
          {
            path: 'requirements',
            name: 'b2b.job-openings.manage.requirements',
            component: () =>
              import(
                './views/JobOpenings/manage/Step3_B2bJobOpeningManageRequirements.vue'
              ),
          },
          {
            path: 'finish',
            name: 'b2b.job-openings.manage.finish',
            component: () =>
              import(
                './views/JobOpenings/manage/Step4_B2bJobOpeningManageFinish.vue'
              ),
          },
        ],
      },
      {
        path: 'job-openings/:jobOpeningId',
        name: 'b2b.job-openings.details',
        component: () =>
          import('./views/JobOpenings/details/B2bJobOpeningDetails.vue'),
        redirect: (to: any) => ({
          name: 'b2b.job-openings.details.reco',
          params: { jobOpeningId: to.params.jobOpeningId },
        }),
        meta: {
          title: 'Bedarfsmeldung Details',
        } as RouteMeta,
        children: [
          {
            path: 'overview',
            name: 'b2b.job-openings.details.overview',
            component: () =>
              import(
                './views/JobOpenings/details/B2bJobOpeningDetailsOverview.vue'
              ),
          },
          {
            path: 'reco',
            name: 'b2b.job-openings.details.reco',
            component: () =>
              import(
                './views/JobOpenings/details/B2bJobOpeningDetailsReco.vue'
              ),
          },
          {
            path: 'contacted',
            name: 'b2b.job-openings.details.contacted',
            component: () =>
              import(
                './views/JobOpenings/details/B2bJobOpeningDetailsContacted.vue'
              ),
          },
        ],
      },
      {
        path: 'job-openings/:jobOpeningId/applicants/:applicantId',
        name: 'b2b.job-openings.applicant-details',
        component: () =>
          import('./views/JobOpenings/B2bJobOpeningApplicant.vue'),
        meta: {
          title: 'Bewerberdetails',
          icon: 'graduate',
        } as RouteMeta,
      },
      {
        path: 'job-openings/:jobOpeningId/applicants/:applicantId/contract',
        name: 'b2b.job-openings.applicant-contract',
        component: () =>
          import('./views/JobOpenings/B2bJobOpeningApplicantContract.vue'),
        meta: {
          title: 'Vertragserstellung',
          icon: 'graduate',
        } as RouteMeta,
      },
      {
        path: 'job-openings/:jobOpeningId/applicants/:applicantId/success',
        name: 'b2b.job-openings.success',
        component: () => import('./views/JobOpenings/SuccessPage.vue'),
        meta: {
          title: 'Vielen Dank',
          icon: 'graduate',
        } as RouteMeta,
      },
      {
        path: 'help',
        name: 'b2b.help',
        redirect: () => ({
          name: 'b2b.help.start',
        }),
        component: () => import('./views/B2bHelpStart.vue'),
        meta: {
          title: 'Hilfe & Kontakt',
          icon: 'envelope',
        } as RouteMeta,
        children: [
          {
            path: '',
            name: 'b2b.help.start',
            component: () => import('./views/B2bHelpView.vue'),
          },
          {
            path: 'contact',
            name: 'b2b.help.contact',
            component: () => import('./views/B2bContactView.vue'),
          },
          {
            path: 'contact/:supportTicketId',
            name: 'b2b.help.contact.ticket',
            component: () => import('./views/B2bSupportTicketView.vue'),
            meta: {
              title: 'Kontakt',
              icon: 'envelope',
            } as RouteMeta,
          },
        ],
      },
      {
        path: 'help/:helpTopic',
        name: 'b2b.help.topic',
        component: () => import('./views/B2bHelpTopicView.vue'),
        meta: {
          title: 'Kontakt',
          icon: 'envelope',
        } as RouteMeta,
      },
      {
        path: 'notifications',
        name: 'b2b.notifications',
        component: () => import('./views/B2bNotificationsView.vue'),
        meta: {
          title: 'Mitteilungen',
          icon: 'notes',
        } as RouteMeta,
      },
      {
        path: 'company',
        name: 'b2b.company',
        redirect: () => ({
          name: 'b2b.company.data',
        }),
        component: () => import('./views/company/Company.vue'),
        meta: {
          title: 'Unternehmen',
          icon: 'user',
        } as RouteMeta,
        children: [
          {
            path: 'data',
            name: 'b2b.company.data',
            component: () => import('./views/company/Data.vue'),
          },
          {
            path: 'portrait',
            name: 'b2b.company.portrait',
            component: () => import('./views/company/Portrait.vue'),
          },
        ],
      },
      {
        path: 'employees',
        name: 'b2b.employees',
        component: () => import('./views/B2bEmployeeControl.vue'),
        meta: {
          title: 'Nutzerverwaltung',
          icon: 'users',
        } as RouteMeta,
      },
      {
        path: 'employees/edit/:eId?',
        name: 'b2b.employees.edit',
        component: () => import('./views/B2bEmployeeEditControl.vue'),
        meta: {
          title: 'Nutzerverwaltung',
          icon: 'users',
        } as RouteMeta,
      },
      {
        path: 'news',
        name: 'b2b.news',
        component: () => import('./views/B2bNewsView.vue'),
        meta: {
          title: 'Aktuelles',
          icon: 'home',
        } as RouteMeta,
      },
      {
        path: 'news/:id',
        name: 'b2b.news.show',
        component: () => import('./views/B2bNewsDetailsView.vue'),
        meta: {
          title: 'Aktuelles',
          icon: 'home',
        } as RouteMeta,
      },
      {
        path: 'invoices',
        name: 'b2b.invoices',
        component: () => import('./views/B2bInvoicesView.vue'),
        meta: {
          title: 'Rechnungen',
          icon: 'euro',
        } as RouteMeta,
      },
    ],
  },
]

export default b2bUnitRoutes
