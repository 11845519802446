import { reactive, readonly } from 'vue'
import { EnumsDomStorage, FrontendErrorCode } from '@/types/enums'
import UnitStoreData from '@/types/UnitStoreData'
import apiService from '@/services/api.service'
import accountStore from '@/stores/account.store'
import appStore from '@/stores/app.store'
import cloudinaryProfilFormatter from '@/functions/cloudinaryProfilFormatter'

const state = reactive({
  activeApplicationId: parseInt(
    localStorage.getItem(EnumsDomStorage.ACTIVE_APPLICATION) || '',
  ),
} as UnitStoreData)

/**
 * Overwrite complete unit object with new data
 * @param data UnitStoreData['unit']
 */
const setUnitData = async (data: UnitStoreData['unit']) => {
  state.unit = data
  // validate unitdata
  if (!state.unit) {
    await appStore.setApiError(
      FrontendErrorCode.NO_UNIT,
      'No unit found for this account',
    )
    return
  }
  if (state.unit.applications?.length <= 0) {
    await appStore.setApiError(
      FrontendErrorCode.NO_APPLICATION,
      'No application found for this account',
    )
    return
  }
}

/**
 * Set the active application id
 * @param applicationId string
 */
const setActiveApplicationId = (applicationId?: number) => {
  state.activeApplicationId = applicationId ?? getPrimaryApplicationId()
  localStorage.setItem(
    EnumsDomStorage.ACTIVE_APPLICATION,
    state.activeApplicationId.toString(),
  )
}

/**
 * Return the currently active/selected application
 */
const getActiveApplication = () => {
  return getApplicationObjectById(state.activeApplicationId)
}

/**
 * Returns the id of the primary application
 */
const getPrimaryApplicationId = () => {
  console.log('getPrimaryApplicationId')
  const application = state.unit.applications.find(
    (application) => application.primary === true,
  )
  return !application ? state.unit.applications[0].id : application?.id
}

/**
 * Returns the an application object by id, oder primary application if no portal key is given
 * @param id string|null
 */
const getApplicationObjectById = (id?: number) => {
  if (!state.unit?.applications) {
    return null
  }

  let application = state.unit.applications.find(
    (application) => application.id === (id ? id : getPrimaryApplicationId()),
  )

  if (!application) {
    application = state.unit.applications.find(
      (application) => application.id === getPrimaryApplicationId(),
    )
  }

  return application
}

const _getWhatsAppLink = (phone?: string) => {
  if (!phone) {
    return null
  }
  const mobileParsed = phone.replace('+', '')
  return `https://api.whatsapp.com/send/?phone=${mobileParsed}&text&app_absent=0`
}

const getAdvisorContact = () => {
  const imageUrl = state.unit?.advisor?.imagePath
    ? cloudinaryProfilFormatter(state.unit.advisor.imagePath)
    : getApplicationObjectById()?.campus.imagePath
  return {
    name: state.unit?.advisor?.name ?? '',
    whatsapp: state.unit?.advisor?.whatsappAvailable
      ? _getWhatsAppLink(state.unit?.advisor?.mobilePhone)
      : _getWhatsAppLink(import.meta.env.VITE_CSC_WHATSAPP),
    phone: state.unit?.advisor?.phone ?? import.meta.env.VITE_CSC_PHONE,
    mobilePhone:
      state.unit?.advisor?.mobilePhone &&
      state.unit?.advisor?.mobilePhone?.length > 0
        ? state.unit?.advisor?.mobilePhone
        : null,
    email: state.unit?.advisor?.email ?? import.meta.env.VITE_CSC_EMAIL,
    image: imageUrl,
    city: state.unit?.advisor?.city ?? getApplicationObjectById()?.campus.city,
  }
}

const getCscContact = () => {
  return {
    phone: import.meta.env.VITE_CSC_PHONE ?? null,
    email: import.meta.env.VITE_CSC_EMAIL ?? null,
    whatsapp: import.meta.env.VITE_CSC_WHATSAPP ?? null,
  }
}

const getCampusNameObj = () => {
  const cleanCampusName = getActiveApplication()?.campus?.name.replace(
    '(Duales Studium)',
    '',
  )
  return {
    name: cleanCampusName,
    phrase: cleanCampusName?.startsWith('Virtueller Campus')
      ? 'Dein Kontakt am Virtuellen Campus'
      : `Dein Kontakt in ${cleanCampusName}`,
  }
}

const fetchUnitData = async () => {
  return await apiService.unit.getUnit().then((result) => {
    setUnitData(result.data)
  })
}

const stageMinimumReady = (): boolean => {
  return [
    'Examined Application',
    'Ready to match',
    'In Matching',
    'Contract Proposal',
  ].includes(state.unit?.stage)
}

export default {
  state: readonly(state),
  setUnitData,
  getPrimaryApplicationId,
  setActiveApplicationId,
  getApplicationObjectById,
  fetchUnitData,
  getActiveApplication,
  getAdvisorContact,
  getCampusNameObj,
  getCscContact,
  stageMinimumReady,
}
