<template>
  <vue-final-modal
    class="iu-modal"
    v-slot="{ params, close }"
    v-bind="$attrs"
    classes="iu-modal-container"
    :content-class="[contentClass]"
  >
    <!-- Modal header -->
    <div
      class="h-20 py-4 px-6 flex items-center justify-between w-full"
      :class="[`bg-${headerColor}`]"
    >
      <h3 class="font-bold text-xl text-black uppercase">
        <slot name="title"></slot>
      </h3>
      <div
        role="button"
        v-if="!props.hideClose"
        class="focus:outline-none focus:ring-1 focus:ring-blue-dark p-2"
        tabindex="0"
        @click="$emit('closed', close)"
      >
        <svg-icon name="close" class="text-lg font-bold" />
      </div>
    </div>

    <!-- Modal body -->
    <div
      class="flex flex-col overflow-hidden overflow-y-auto px-2 py-4 md:px-6 bg-gray-50 max-h-full"
      :style="dynamicContent ? '' : 'height: 58vh'"
    >
      <slot :params="params"></slot>
    </div>

    <!-- Modal footer -->
    <div class="modal__footer" v-if="!hideFooter">
      <button @click="$emit('confirm', close)">confirm</button>
      <button @click="$emit('cancel', close)">cancel</button>
    </div>
  </vue-final-modal>
</template>

<script lang="ts">
export default {
  inheritAttrs: false,
}
</script>

<script setup lang="ts">
import SvgIcon from '@/components/SvgIcon.vue'
import { computed } from 'vue'
import { useRoute } from 'vue-router'

const route = useRoute()

const emit = defineEmits(['confirm', 'cancel'])

const props = defineProps<{
  contentClass?: string
  dynamicContent?: boolean
  hideFooter?: boolean
  color?: string
  hideClose?: boolean
}>()

const headerColor = computed(() => props.color ?? route.meta.color ?? 'orange')
</script>

<style>
.iu-modal-container {
  @apply flex justify-center items-center z-50;
}
</style>
