<template>
  <router-link
    v-if="useAs === 'router-link' && !disabled"
    :to="to"
    :class="buttonClasses"
    :target="newWindow ? '_blank' : ''"
    @click="
      appService.pushToDataLayer(
        trackingEventname || null,
        trackingLabel || null,
        trackingType || null,
        trackingPosition || null,
      )
    "
    :data-t-c="legacyTracking?.category || null"
    :data-t-a="legacyTracking?.action || null"
    :data-t-l="legacyTracking?.label || null"
  >
    <slot />
  </router-link>
  <a
    v-else-if="useAs === 'a' && !disabled"
    :href="to"
    :target="newWindow ? '_blank' : ''"
    rel="noopener noreferrer"
    :class="buttonClasses"
    @click="
      appService.pushToDataLayer(
        trackingEventname || null,
        trackingLabel || null,
        trackingType || null,
        trackingPosition || null,
      )
    "
    :data-t-c="legacyTracking?.category || null"
    :data-t-a="legacyTracking?.action || null"
    :data-t-l="legacyTracking?.label || null"
  >
    <slot />
  </a>
  <button
    v-else
    :class="buttonClasses"
    :disabled="disabled"
    @click="
      appService.pushToDataLayer(
        trackingEventname || null,
        trackingLabel || null,
        trackingType || null,
        trackingPosition || null,
      )
    "
    :data-t-c="legacyTracking?.category || null"
    :data-t-a="legacyTracking?.action || null"
    :data-t-l="legacyTracking?.label || null"
  >
    <slot />
  </button>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import LegacyTrackingConfig from '@/types/LegacyTrackingConfig'
import appService from '@/services/app.service'

const props = defineProps<{
  type?: string
  disabled?: boolean
  noSpacing?: boolean
  useAs?: string
  to?: string | object
  newWindow?: boolean
  trackingEventname?: string
  trackingLabel?: string
  trackingPosition?: string
  trackingType?: string
  legacyTracking?: LegacyTrackingConfig
}>()
const buttonType = computed(() => props.type ?? 'primary')
const buttonClasses = computed(() =>
  props.noSpacing
    ? `btn-${buttonType.value}`
    : `btn-${buttonType.value} px-6 py-3 min-w-30`,
)
</script>

<style scoped>
button,
a {
  @apply inline-flex uppercase font-bold items-center justify-center transition-colors duration-150 ease-in-out disabled:opacity-50 disabled:cursor-not-allowed focus:ring-2;
  @apply cursor-pointer;
}
button.btn-primary,
a.btn-primary {
  @apply bg-cta text-white border-2 border-cta hover:bg-cta-light hover:border-cta-light;
}
button.btn-secondary,
a.btn-secondary {
  @apply border-3 border-cta text-black hover:border-cta-light;
}
button.btn-text,
a.btn-text {
  @apply underline text-black hover:no-underline;
}
button.btn-tertiary,
a.btn-tertiary {
  @apply border border-black text-black hover:border-cta-light;
}
button.btn-custom,
a.btn-custom {
  @apply focus:ring-0;
}
</style>
